<template>
  <Modal :open="open" title="Preset Options" @close="onClose">
    <template #default>
      <div class="py-1 divide-y divide-gray-100 mt-4">
        <div
          v-for="item in items"
          :key="item.name"
          class="inline-flex items-center py-3 w-full text-gray-700"
        >
          <div class="mr-2">
            <p class="text-sm font-medium">{{ item.name }}</p>
            <p class="text-xs text-gray-500 ml-auto">
              {{ item.tooltip }}
            </p>
          </div>
          <ButtonBase
            :neutral="item.btnType === 'neutral'"
            :error="item.btnType === 'error'"
            xs
            class="ml-auto"
            :data-cy="item.dataCy"
            @click="item.onClick()"
          >
            {{ item.btnText }}
          </ButtonBase>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  presetId: {
    type: Number,
    required: true,
  },
});

type ReportOption = {
  name: string;
  onClick: () => void;
  btnText: string;
  btnType: string;
  tooltip: string;
  dataCy: string;
};

const items = computed<ReportOption[]>(() => [
  {
    name: "Edit preset",
    onClick: onEdit,
    tooltip: "Edit preset configuration",
    btnText: "Edit",
    btnType: "neutral",
    dataCy: "edit-preset",
  },
  {
    name: "Duplicate preset",
    onClick: onDuplicate,
    tooltip: "Duplicate preset within the client or to another client",
    btnText: "Duplicate",
    btnType: "neutral",
    dataCy: "duplicate-preset",
  },
  {
    name: "Delete preset",
    onClick: onDelete,
    tooltip: "Delete the preset from this client",
    btnText: "Delete",
    btnType: "error",
    dataCy: "delete-preset",
  },
]);

const onClose = async () => {
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: undefined,
      presetId: undefined,
    },
  });
};

const router = useRouter();
const route = useRoute();
const metricsStore = useMetricsStore();
const { notify } = useNotifications();

const onEdit = async () => {
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "edit-preset",
      presetId: props.presetId,
    },
  });
};

const onDuplicate = async () => {
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "duplicate-preset",
      presetId: props.presetId,
    },
  });
};

const onDelete = async () => {
  const confirmed = confirm(
    "Are you sure you want to delete this preset? This action cannot be undone.",
  );

  if (!confirmed) {
    return;
  }

  const errorMaybe = await metricsStore.deleteMetricPreset(props.presetId);
  notify(errorMaybe, "Preset deleted.");
  onClose();
};
</script>

<template>
  <!-- Open/close sidebar for mobile -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-30 lg:hidden" @close="onCloseSidebar">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5"
              >
                <button
                  type="button"
                  class="-m-2.5 p-2.5"
                  @click="onCloseSidebar"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <SidebarMainNav />
            <SidebarSecondaryNav v-if="hasSecondaryNav" :loading="loading" />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { NO_SIDEBAR_PAGES } from "~/shared/constants";

defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
  sidebarOpen: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const route = useRoute();
const hasSecondaryNav = computed(
  // Important: Use starts with as includes with /connect would make /settings/connections disappear
  () => !NO_SIDEBAR_PAGES.some((p) => route.path.startsWith(p)),
);

const emit = defineEmits(["close-sidebar"]);

const onCloseSidebar = () => {
  emit("close-sidebar");
};
</script>

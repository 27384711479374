<template>
  <Modal
    size="sm:max-w-2xl sm:min-h-[24rem]"
    :open="props.open"
    title="Duplicate preset"
    @close="onClose"
  >
    <template #default>
      <div class="mt-2">
        <SelectClients
          label="Duplicate to clients"
          :selected-client-ids="selClientIds"
          @update:selected="selClientIds = $event"
        />
      </div>
    </template>
    <template #actions>
      <ButtonBase primary :loading="isLoading" class="ml-2" @click="onDuplicate"
        >Duplicate</ButtonBase
      >
      <ButtonBase neutral @click="onClose">Close</ButtonBase>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  presetId: {
    type: Number,
    required: true,
  },
});

const router = useRouter();
const route = useRoute();
const { notify } = useNotifications();
const isLoading = ref(false);
const selClientIds = ref<number[]>([]);
const store = useMetricsStore();

const onClose = () => {
  router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: undefined,
    },
  });
};

const onDuplicate = async () => {
  if (selClientIds.value.length === 0) {
    notify("Please select at least one client", "");
    return;
  }

  isLoading.value = true;
  let errorMaybe: string | null = null;
  for (const clientId of selClientIds.value) {
    const presetMaybe = await store.duplicateMetricPreset({
      presetId: props.presetId,
      clientId,
    });
    if (typeof presetMaybe === "string") {
      errorMaybe = presetMaybe;
    } else if (presetMaybe == null) {
      errorMaybe = "Failed to duplicate preset";
    }
  }
  notify(errorMaybe, "Preset duplicated successfully");
  if (!errorMaybe) {
    onClose();
  }
  isLoading.value = false;
};
</script>

<template>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="gap-y-1">
      <li v-for="item in navigation" :key="item.name">
        <nuxt-link
          :to="item.href"
          :class="[
            item.current
              ? 'bg-gray-50 text-primary-400'
              : 'text-gray-700 hover:bg-gray-50',
            'group flex gap-x-3 rounded-md px-2 py-[0.35rem] text-sm leading-4 items-center',
          ]"
          :data-cy="item.dataCy"
        >
          <component
            :is="item.icon"
            :class="[
              item.current ? 'text-primary-400' : 'text-gray-400',
              'h-4 w-4 shrink-0',
            ]"
            aria-hidden="true"
          />{{ item.name }}</nuxt-link
        >
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import {
  BuildingOffice2Icon,
  ChartBarIcon,
  CreditCardIcon,
  FolderIcon,
  GiftIcon,
  ShieldCheckIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
const { isAdmin } = useAppUser();
const { appUserMe } = storeToRefs(useAppUserStore());
const navigation = computed(() =>
  [
    {
      name: "Profile",
      href: "/settings/profile",
      current: useIsCurrentRoute("/settings/profile"),
      icon: UserCircleIcon,
      dataCy: "sidebar-nav-settings-profile",
      enabled: true,
    },
    {
      name: "Team",
      href: "/settings/team",
      current: useIsCurrentRoute("/settings/team"),
      icon: UserGroupIcon,
      enabled: isAdmin(appUserMe.value),
    },
    {
      name: "Clients",
      href: "/settings/clients",
      current: useIsCurrentRoute("/settings/clients"),
      icon: FolderIcon,
      enabled: true,
    },
    {
      name: "Connections",
      href: "/settings/connections",
      current: useIsCurrentRoute("/settings/connections"),
      icon: SquaresPlusIcon,
      enabled: isAdmin(appUserMe.value),
    },
    {
      name: "Legal",
      href: "/settings/legal",
      current: useIsCurrentRoute("/settings/legal"),
      icon: ShieldCheckIcon,
      enabled: true,
    },
    {
      name: "Workspace",
      href: "/settings/workspace",
      current: useIsCurrentRoute("/settings/workspace"),
      icon: BuildingOffice2Icon,
      enabled: true,
    },
    {
      name: "Billing",
      href: "/settings/billing",
      current: useIsCurrentRoute("/settings/billing"),
      icon: CreditCardIcon,
      enabled: isAdmin(appUserMe.value),
    },
    {
      name: "Usage",
      href: "/settings/usage",
      current: useIsCurrentRoute("/settings/usage"),
      icon: ChartBarIcon,
      enabled: isAdmin(appUserMe.value),
    },
    {
      name: "Referral",
      href: "/settings/referral",
      current: useIsCurrentRoute("/settings/referral"),
      icon: GiftIcon,
      dataCy: "sidebar-nav-settings-referral",
      enabled: true,
    },
  ].filter((item) => item.enabled),
);
</script>

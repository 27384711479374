<template>
  <Modal :open="open" title="✋ Premium Feature" @close="onClose">
    <template #default>
      <div class="mt-2">
        <p class="text-sm text-gray-500">
          This feature is only available on a higher plan. Upgrade your plan to
          access this feature.
        </p>
      </div></template
    >
    <template #actions>
      <nuxt-link
        to="/payment/select-plan"
        class="ml-2 inline-flex items-center justify-center disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200 px-3 py-2 text-sm rounded-md bg-primary-400 font-semibold text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
        >Upgrade plan</nuxt-link
      >
    </template>
  </Modal>
</template>

<script lang="ts" setup>
defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const router = useRouter();
const route = useRoute();

const onClose = () => {
  router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: undefined,
    },
  });
};
</script>

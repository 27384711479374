<template>
  <div
    class="flex flex-col gap-y-1 overflow-y-auto border-r border-gray-200 bg-white p-2 min-w-12 max-w-12"
  >
    <nuxt-link
      v-tooltip="'Home'"
      to="/"
      class="mt-[0.45rem] rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-transparent hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1"
    >
      <img class="w-full h-auto" src="~/assets/img/logo.png" alt="DatAds" />
    </nuxt-link>
    <div class="relative my-2">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
    </div>
    <nuxt-link
      v-if="!hasNoConnection"
      v-tooltip="'Creative Analytics'"
      to="/"
      :class="[
        'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isAnalytics ? 'bg-gray-100' : 'bg-transparent',
      ]"
    >
      <ChartBarSquareIcon
        :class="['h-5 w-5', isAnalytics ? 'text-primary-400' : 'text-gray-500']"
        aria-hidden="true"
      />
    </nuxt-link>
    <a
      v-if="!hasNoConnection"
      v-tooltip="'Inspiration'"
      href="#"
      type="button"
      :class="[
        'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isInspiration ? 'bg-gray-100' : 'bg-transparent',
      ]"
      @click.prevent="onOpenInspirationBoard"
    >
      <PhotoIcon
        :class="[
          'h-5 w-5',
          isInspiration ? 'text-primary-400' : 'text-gray-500',
        ]"
        aria-hidden="true"
      />
    </a>
    <nuxt-link
      v-if="!hasNoConnection && canManage"
      v-tooltip="'Tagging'"
      to="/tagging/unlabeled"
      data-cy="sidebar-tagging"
      :class="[
        'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isTagging ? 'bg-gray-100' : 'bg-transparent',
      ]"
    >
      <TagIcon
        :class="['h-5 w-5', isTagging ? 'text-primary-400' : 'text-gray-500']"
        aria-hidden="true"
      />
    </nuxt-link>
    <nuxt-link
      v-if="canManage && activeClient"
      v-tooltip="'Getting started'"
      to="/onboarding/getting-started"
      data-cy="sidebar-onboarding"
      :class="[
        'mt-auto rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isGettingStarted ? 'bg-gray-100' : 'bg-transparent',
      ]"
    >
      <ProgressRadial
        :color="
          getColor(getClientCompletionPercentage(activeClient, activeWorkspace))
            .text
        "
        :progress="getClientCompletionPercentage(activeClient, activeWorkspace)"
        :min-progress="minOnboardingPercentage"
      />
    </nuxt-link>
    <nuxt-link
      v-tooltip="'Settings'"
      to="/settings/clients"
      data-cy="sidebar-main-nav-settings"
      :class="[
        'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isSettings ? 'bg-gray-100' : 'bg-transparent',
        !canManage || !activeClient ? 'mt-auto' : '',
      ]"
    >
      <Cog8ToothIcon
        :class="['h-5 w-5', isSettings ? 'text-primary-400' : 'text-gray-500']"
        aria-hidden="true"
      />
    </nuxt-link>
    <nuxt-link
      v-if="canManage"
      v-tooltip="'Add connection'"
      to="/settings/connections"
      :class="[
        'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isConnection ? 'bg-gray-100' : 'bg-transparent',
      ]"
    >
      <SquaresPlusIcon
        :class="[
          'h-5 w-5',
          isConnection ? 'text-primary-400' : 'text-gray-500',
        ]"
        aria-hidden="true"
      />
    </nuxt-link>
    <a
      v-tooltip="'Knowledge base'"
      :href="HELP_PAGE"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-transparent hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1"
    >
      <QuestionMarkCircleIcon
        class="h-5 w-5 text-gray-500"
        aria-hidden="true"
      />
    </a>
    <a
      v-tooltip="'Book onboarding session'"
      :href="SUPPORT_CALL_URL"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-transparent hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1"
    >
      <PhoneIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
    </a>
    <nuxt-link
      v-tooltip="'Refer a friend & earn up to 20% commission'"
      data-cy="sidebar-main-nav-refer"
      to="/settings/referral"
      :class="[
        'rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-100 focus-visible:outline-gray-200 border border-transparent p-1',
        isRefer ? 'bg-gray-100' : 'bg-transparent',
      ]"
    >
      <GiftIcon
        :class="['h-5 w-5', isRefer ? 'text-primary-400' : 'text-gray-500']"
        aria-hidden="true"
    /></nuxt-link>
    <div class="relative my-2">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
    </div>
    <nuxt-link
      v-if="appUserMe"
      v-tooltip="'To your profile'"
      to="/settings/profile"
      class="rounded-full p-1 hover:bg-gray-100"
    >
      <span class="sr-only">Your profile</span>
      <img
        class="h-6 w-6 rounded-full bg-gray-50"
        :src="appUserMe.profileImage"
        :alt="appUserMe.displayName"
      />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import {
  Cog8ToothIcon,
  GiftIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  TagIcon,
  SquaresPlusIcon,
  ChartBarSquareIcon,
  PhotoIcon,
} from "@heroicons/vue/24/outline";
import { HELP_PAGE, SUPPORT_CALL_URL } from "~/shared/constants";

const appUserHelpers = useAppUser();
const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const isAdmin = computed(() => appUserHelpers.isAdmin(appUserMe.value));
const isMember = computed(() => appUserHelpers.isMember(appUserMe.value));
const canManage = computed(() => isAdmin.value || isMember.value);

const { getActiveWorkspace } = useWorkspace();
const activeWorkspace = computed(() => getActiveWorkspace(appUserMe.value));

const { hasFeature } = useFeatureFlags();
const hasInspirationBoard = computed(() =>
  hasFeature("inspiration-board_2024-01-01"),
);

const router = useRouter();
const route = useRoute();

const onOpenInspirationBoard = async () => {
  if (hasInspirationBoard.value) {
    await router.push("/inspiration-board/getting-started");
  } else {
    await router.push({
      path: route.path,
      query: {
        ...route.query,
        mode: "premium-feature",
      },
    });
  }
};
const { activeClient } = storeToRefs(useClientStore());
const { getClientCompletionPercentage, getColor, minOnboardingPercentage } =
  useOnboarding();
const isTagging = computed(() => useIsCurrentRoute("/tagging"));
const isInspiration = computed(() => useIsCurrentRoute("/inspiration-board"));
const isGettingStarted = computed(() =>
  useIsCurrentRoute("/onboarding/getting-started"),
);
const hasNoConnection = computed(() =>
  useIsCurrentRoute("/onboarding/create-connection"),
);
const isSettings = computed(() =>
  useIsCurrentRoute("/settings", {
    noMatchPaths: ["/settings/connections", "/settings/referral"],
  }),
);
const isConnection = computed(() =>
  useIsCurrentRoute("/settings/connections", { matchExact: true }),
);
const isRefer = computed(() => useIsCurrentRoute("/settings/referral"));
const isAnalytics = computed(
  () =>
    useIsCurrentRoute("/home") ||
    useIsCurrentRoute("/creative-comparison") ||
    useIsCurrentRoute("/creative-library") ||
    useIsCurrentRoute("/creative-reporting") ||
    useIsCurrentRoute("/launch") ||
    useIsCurrentRoute("/testing-log"),
);
</script>

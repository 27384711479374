<template>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="gap-y-1">
      <li>
        <a
          href="#"
          type="button"
          data-cy="tagging-toggle-unlabeled"
          :class="[
            useIsCurrentRoute('/tagging/unlabeled')
              ? 'bg-gray-50 text-primary-400'
              : 'text-gray-700 hover:bg-gray-50',
            'group flex gap-x-3 rounded-md px-2 py-[0.35rem] text-sm leading-4 items-center',
          ]"
          @click.prevent="onClickBtn('/tagging/unlabeled')"
        >
          <Squares2X2Icon
            :class="[
              useIsCurrentRoute('/tagging/unlabeled')
                ? 'text-primary-400'
                : 'text-gray-400',
              'h-4 w-4 shrink-0',
            ]"
            aria-hidden="true"
          />
          Unlabeled
        </a>
      </li>
      <li>
        <a
          href="#"
          type="button"
          data-cy="tagging-toggle-labeled"
          :class="[
            useIsCurrentRoute('/tagging/labeled')
              ? 'bg-gray-50 text-primary-400'
              : 'text-gray-700 hover:bg-gray-50',
            'group flex gap-x-3 rounded-md px-2 py-[0.35rem] text-sm leading-4 items-center',
          ]"
          @click.prevent="onClickBtn('/tagging/labeled')"
        >
          <CheckBadgeIcon
            :class="[
              useIsCurrentRoute('/tagging/labeled')
                ? 'text-primary-400'
                : 'text-gray-400',
              'h-4 w-4 shrink-0',
            ]"
            aria-hidden="true"
          />
          Labeled
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { CheckBadgeIcon, Squares2X2Icon } from "@heroicons/vue/24/outline";
const router = useRouter();
const route = useRoute();
const onClickBtn = async (path: string) => {
  await router.push({
    path,
    query: { ...route.query },
  });
};
</script>

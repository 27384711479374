<template>
  <VueDraggableNext
    tag="ul"
    group="files"
    :model-value="navigation"
    class="space-y-1"
    role="list"
    ghost-class="ghost"
    :disabled="!withFolders || !canManage"
    @change="onDrag"
  >
    <li
      v-for="(navEl, idx) of navigation"
      :key="navEl.id"
      data-cy="sidebar-subnavigation-element"
      :data-intercom-target="'sidebar-subnavigation-element-' + idx"
    >
      <nuxt-link
        :to="navEl.href"
        :class="[
          navEl.current
            ? 'bg-gray-50 text-primary-400'
            : 'text-gray-700 hover:bg-gray-50',
          'group flex rounded-md px-2 py-[0.35rem] text-sm leading-4 items-center',
        ]"
        data-cy="sidebar-subnavigation-link"
      >
        <component
          :is="getIconComponent(navEl.type)"
          :class="[
            'h-4 w-4 shrink-0 p-[0.1rem] mr-2 bg-gray-100 border border-transparent rounded',
            getIconColor(navEl.type),
          ]"
          aria-hidden="true"
        />
        <span v-tooltip="navEl.name" class="truncate">{{ navEl.name }}</span>
        <Menu
          v-if="canManage"
          v-tooltip="'Delete, duplicate, and more...'"
          as="div"
          class="relative ml-auto h-4"
        >
          <MenuButton
            type="button"
            class="rounded-md px-1 text-white group-hover:text-gray-700 bg-transparent hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            data-cy="sidebar-subnavigation-menu"
          >
            <EllipsisHorizontalIcon class="h-4 w-4" aria-hidden="true" />
          </MenuButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    type="button"
                    :class="[
                      active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                      'inline-flex px-4 py-2 text-xs w-full',
                    ]"
                    @click.stop="shareElt(navEl)"
                  >
                    <LinkIcon class="h-4 w-4 shrink-0 mr-2" /><span
                      >Share {{ fileType(navEl.type) }}</span
                    >
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <nuxt-link
                    :to="navEl.duplicateHref"
                    :class="[
                      active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                      'inline-flex px-4 py-2 text-xs w-full',
                    ]"
                    data-cy="sidebar-subnavigation-duplicate"
                  >
                    <DocumentDuplicateIcon class="h-4 w-4 shrink-0 mr-2" /><span
                      >Duplicate {{ fileType(navEl.type) }}</span
                    >
                  </nuxt-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    type="button"
                    :class="[
                      active ? 'bg-gray-100 text-gray-700' : 'text-gray-700',
                      'inline-flex px-4 py-2 text-xs w-full',
                    ]"
                    data-cy="sidebar-subnavigation-delete"
                    @click.stop="deleteElt(navEl)"
                  >
                    <TrashIcon class="h-4 w-4 shrink-0 mr-2" /><span
                      >Delete {{ fileType(navEl.type) }}</span
                    >
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </nuxt-link>
    </li>
  </VueDraggableNext>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import {
  TrashIcon,
  DocumentDuplicateIcon,
  LinkIcon,
} from "@heroicons/vue/24/outline";
import { EllipsisHorizontalIcon } from "@heroicons/vue/20/solid";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { VueDraggableNext } from "vue-draggable-next";
import { NavigationElement } from "~/types/shared";

defineProps({
  navigation: {
    type: Array as PropType<NavigationElement[]>,
    required: true,
    default: () => [],
  },
  canManage: {
    type: Boolean,
    required: true,
    default: false,
  },
  withFolders: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const { getIconColor, getIconComponent } = useSidebar();

const fileType = (type: string) => {
  switch (type) {
    case "board":
      return "Board";
    default:
      return "Report";
  }
};

const emit = defineEmits(["delete", "share", "drag"]);

const deleteElt = (elt: { id: string; type: string }) => {
  emit("delete", elt);
};

const shareElt = (elt: { id: string; type: string }) => {
  emit("share", elt);
};

const onDrag = (event: {
  added?: { element: NavigationElement; newIndex: number };
  removed?: { element: NavigationElement; oldIndex: number };
  moved?: { element: NavigationElement; oldIndex: number; newIndex: number };
}) => {
  emit("drag", event);
};
</script>

<style scoped lang="scss">
.ghost {
  @apply bg-gray-100 border border-dashed border-gray-300 rounded-md;
}
</style>

<template>
  <div
    :class="[
      'hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex ',
      hasSecondaryNav ? 'lg:w-72' : '',
    ]"
  >
    <SidebarMainNav />
    <SidebarSecondaryNav v-if="hasSecondaryNav" :loading="loading" />
  </div>
</template>

<script setup lang="ts">
import { NO_SIDEBAR_PAGES } from "~/shared/constants";

defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const route = useRoute();
const hasSecondaryNav = computed(
  // Important: Use starts with as includes with /connect would make /settings/connections disappear
  () => !NO_SIDEBAR_PAGES.some((p) => route.path.startsWith(p)),
);
</script>

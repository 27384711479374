<template>
  <div class="lg:pl-72">
    <div
      v-if="showNavbar"
      class="sticky top-0 z-30 bg-transparent px-4 py-2 sm:px-6 max-w-screen-2xl mx-auto"
    >
      <div class="flex items-center justify-between">
        <div class="flex flex-col gap-y-2">
          <div
            v-if="showTrialExpiresSoon"
            class="flex items-center flex-1 gap-x-4 self-stretch bg-yellow-50 px-4 py-2 rounded-md border border-yellow-200"
          >
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon
                class="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <p class="text-xs text-yellow-700">
              <span class="font-semibold mr-1">{{
                trialExpiresSoonPrefix
              }}</span
              ><span class="mr-1"
                >Please add payment details to continue using the app or reach
                out to us for a trial extension.</span
              >
            </p>
            <nuxt-link
              to="/payment/select-plan"
              class="text-xs text-yellow-700 -ml-2 font-semibold bg-yellow-200 px-2 py-1 rounded-md hover:bg-yellow-300"
              >Select Plan &rarr;</nuxt-link
            >
          </div>
          <div
            v-if="showLoadingInitialData"
            class="flex items-center flex-1 gap-x-4 self-stretch bg-yellow-50 px-4 py-2 rounded-md border border-yellow-200"
          >
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon
                class="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <p class="text-xs text-yellow-700">
              <span class="font-semibold mr-1">Loading initial data</span
              ><span class="mr-1">Reports might not show all ads yet.</span>
            </p>
          </div>
          <div
            v-if="showRemoteWarning"
            class="flex items-center flex-1 gap-x-4 self-stretch bg-yellow-50 px-4 py-2 rounded-md border border-yellow-200"
          >
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon
                class="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <p class="text-xs text-yellow-700">
              <span class="font-semibold mr-1">{{ remoteWarning.title }}</span
              ><span class="mr-1">{{ remoteWarning.message }}</span>
            </p>
          </div>
          <div
            v-if="showConnectionWarning"
            class="flex items-center flex-1 gap-x-4 self-stretch bg-yellow-50 px-4 py-2 rounded-md border border-yellow-200"
          >
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon
                class="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <p class="text-xs text-yellow-700">
              <span class="font-semibold mr-1">Action Required!</span
              ><span class="mr-1">{{ connectionWarning }}</span>
            </p>
            <nuxt-link
              to="/settings/connections"
              class="text-xs text-yellow-700 -ml-2 font-semibold bg-yellow-200 px-2 py-1 rounded-md hover:bg-yellow-300"
              >View connections &rarr;</nuxt-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { daysBetween } from "~/shared/utils";
import { Workspace } from "~/types/Workspace";
import { ConnectionStatus } from "~/types/Connection";

const showNavbar = computed(
  () =>
    showTrialExpiresSoon.value ||
    showRemoteWarning.value ||
    showConnectionWarning.value ||
    showLoadingInitialData.value,
);

const appUserHelpers = useAppUser();
const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const isAdmin = computed(() => appUserHelpers.isAdmin(appUserMe.value));
const { getActiveWorkspace } = useWorkspace();
const workspace = computed(() => getActiveWorkspace(appUserMe.value));
const expiresSoon = computed(() => {
  if (workspace.value == null) {
    return false;
  }
  const thresholdDays = 7;
  return (
    workspace.value.subscriptionEndDate &&
    expiresInDays(workspace.value) < thresholdDays &&
    !workspace.value.addedPaymentDetails
  );
});
const expiresInDays = (workspace: Workspace) => {
  if (workspace.subscriptionEndDate == null) {
    return 0;
  }
  return daysBetween(new Date(), workspace.subscriptionEndDate, true);
};

const showTrialExpiresSoon = computed(
  () => expiresSoon.value && workspace.value && isAdmin.value,
);

const trialExpiresSoonPrefix = computed(() => {
  if (workspace.value == null) {
    return "";
  }
  const expire = expiresInDays(workspace.value);
  if (expire === 1) {
    return "Your trial expires in 1 day!";
  }
  if (expire === 0) {
    return "Your trial expires today!";
  }
  if (expire < 0) {
    return "Your trial has expired!";
  }
  return `Your trial expires in ${expire} days!`;
});

const adAccountStore = useAdAccountStore();
const clientStore = useClientStore();
const { activeClient } = storeToRefs(clientStore);

const showLoadingInitialData = computed(() => {
  const clientId = activeClient.value?.id;
  if (clientId == null) {
    return false;
  }
  // Check if every account in client is not fully loaded
  return adAccountStore.notDeletedAdAccounts
    .filter((acc) => acc.clientIds.includes(clientId))
    .every(
      (acc) =>
        acc.isMapped &&
        !acc.hasFetchedLifetimeData &&
        !acc.deleted &&
        acc.clientIds.length > 0,
    );
});

const { hasFeature, getValue } = useFeatureFlags();

const showRemoteWarning = computed(() => hasFeature("warning-header"));

const remoteWarningStr = computed(() => getValue<string>("warning-header"));

const remoteWarning = computed<{ title: string; message: string }>(() => {
  try {
    return JSON.parse(remoteWarningStr.value);
  } catch (e) {
    return { title: "", message: "" };
  }
});

const connectionStore = useConnectionStore();

const connectionsNotOK = computed(() =>
  connectionStore.connections.filter(
    (connection) =>
      connection.status !== ConnectionStatus.FETCHING_INITIAL_DATA &&
      connection.status !== ConnectionStatus.OK,
  ),
);

const showConnectionWarning = computed(() => connectionsNotOK.value.length > 0);

const connectionWarning = computed(() => {
  const count = connectionsNotOK.value.length;
  if (count === 1) {
    return "There is 1 connection with problems.";
  }
  return `There are ${count} connections with problems.`;
});
</script>
